import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const YandexMetrika = ({ metrikaId }) => {
  const location = useLocation();

  useEffect(() => {
    if (window.ym) {
      window.ym(metrikaId, 'hit', location.pathname + location.search);
    }
  }, [location, metrikaId]);

  return null;
};

export default YandexMetrika;
