// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import ArrowBack from './ArrowBack';

// const BookmarkedPages = () => {
//   const navigate = useNavigate();
//   const [bookmarkedPages, setBookmarkedPages] = React.useState(() => {
//     const storedBookmarks = localStorage.getItem('bookmarks');
//     return storedBookmarks ? JSON.parse(storedBookmarks) : {};
//   });

//   const showPage = (book, pageIndex) => {
//     navigate(`/book/${book}/${pageIndex}`);
//   };

//   const books = Object.keys(bookmarkedPages);

//   if (books.length === 0) {
//     return (
//       <div className="content">
//         <ArrowBack />
//         <h2>Избранное</h2>
//         <p>Вы ещё не добавили страницы в избранное</p>
//       </div>
//     );
//   }

//   return (
//     <div className="bookmarked-pages">
//       <ArrowBack />
//       <h2>Избранное</h2>
//       <ul>
//         {books.map((book) => (
//           <li key={book}>
//             <div>{book}</div>
//             <ul>
//               {(bookmarkedPages[book] || []).map((pageIndex) => (
//                 <li key={pageIndex} onClick={() => showPage(book, pageIndex)}>
//                   Страница {pageIndex}
//                 </li>
//               ))}
//             </ul>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default BookmarkedPages;

// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import ArrowBack from './ArrowBack';

// const BookmarkedPages = () => {
//   const navigate = useNavigate();
  
//   // Загрузка данных из localStorage и проверка на массив
//   const [bookmarkedPages, setBookmarkedPages] = React.useState(() => {
//     const storedBookmarks = localStorage.getItem('bookmarks');
//     const parsedBookmarks = storedBookmarks ? JSON.parse(storedBookmarks) : {};

//     // Проверяем, что каждое значение в parsedBookmarks является массивом
//     for (const book in parsedBookmarks) {
//       if (!Array.isArray(parsedBookmarks[book])) {
//         parsedBookmarks[book] = []; // Преобразуем в пустой массив, если не массив
//       }
//     }
//     // for (const book in parsedBookmarks) {
//     //   if (!parsedBookmarks[book].pages || !Array.isArray(parsedBookmarks[book].pages)) {
//     //     parsedBookmarks[book].pages = []; // Преобразуем в пустой массив, если не массив
//     //   }
//     // }

//     return parsedBookmarks;
//   });

//   // Переход к определенной странице книги
//   const showPage = (book, pageIndex) => {
//     navigate(`/book/${book}/${pageIndex}`);
    
//   };

//   const books = Object.keys(bookmarkedPages);


//   if (books.length === 0) {
//     return (
//       <div className="content">
//         <ArrowBack />
//         <h2>Избранное</h2>
//         <p>Вы ещё не добавили страницы в избранное</p>
//       </div>
//     );
//   }

//   return (
//     <div className="content">
//       <ArrowBack />
//       <h2>Избранное</h2>
//       <ul>
//         {books.map((bookKey) => (
          
//           <li key={bookKey}>
            
//             <div><strong>Книга: {bookKey}</strong></div> 
//             <ul>
//               {/* Отображаем список избранных страниц */}
//               {(bookmarkedPages[bookKey] || []).map((pageIndex) => (
//                 <li key={pageIndex} onClick={() => showPage(bookKey, pageIndex)}>
//                   Страница {pageIndex}
//                 </li>
//               ))}
//             </ul>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default BookmarkedPages;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBack from './ArrowBack';

const books = [
  { id: 1001, title: "Листы Сада Мории. Зов.", imageName: "1"},
  { id: 1002, title: "Листы Сада Мории. Озарение", imageName: "2"},
  { id: 1003, title: "Община", imageName: "3" },
  { id: 1004, title: "Агни Йога", imageName: "4" },
  { id: 1005, title: "Беспредельность. Часть I", imageName: "5" },
  { id: 1006, title: "Беспредельность. Часть II", imageName: "6" },
  { id: 1007, title: "Иерархия", imageName: "7" },
  { id: 1008, title: "Сердце", imageName: "8" },
  { id: 1009, title: "Мир Огненный. Часть I", imageName: "9" },
  { id: 1010, title: "Мир Огненный. Часть II", imageName: "10" },
  { id: 1011, title: "Мир Огненный. Часть III", imageName: "11" },
  { id: 1012, title: "Аум", imageName: "12" },
  { id: 1013, title: "Братство", imageName: "13" },
  { id: 1014, title: "Надземное. (3 книги)", imageName: "14" },
];

const BookmarkedPages = () => {
  const navigate = useNavigate();
  
  // Создаем объект для быстрого доступа к названиям книг
  const bookTitles = books.reduce((acc, book) => {
    acc[book.imageName] = book.title;
    return acc;
  }, {});

  // Загрузка данных из localStorage и проверка на массив
  const [bookmarkedPages, setBookmarkedPages] = React.useState(() => {
    const storedBookmarks = localStorage.getItem('bookmarks');
    const parsedBookmarks = storedBookmarks ? JSON.parse(storedBookmarks) : {};

    // Проверяем, что каждое значение в parsedBookmarks является массивом
    for (const book in parsedBookmarks) {
      if (!Array.isArray(parsedBookmarks[book])) {
        parsedBookmarks[book] = []; // Преобразуем в пустой массив, если не массив
      }
    }

    return parsedBookmarks;
  });

  // Переход к определенной странице книги
  const showPage = (book, pageIndex) => {
    navigate(`/book/${book}/${pageIndex}`);
  };

  const booksKeys = Object.keys(bookmarkedPages);

  if (booksKeys.length === 0) {
    return (
      <div className="content">
        <ArrowBack />
        <h2>Избранное</h2>
        <p>Вы ещё не добавили страницы в избранное</p>
      </div>
    );
  }

  return (
    <div className="content">
      <ArrowBack />
      <h2>Избранное</h2>
      <ul>
        {booksKeys.map((bookKey) => (
          <li key={bookKey}>
            {/* Выводим название книги */}
            <div><strong>{bookTitles[bookKey]}</strong></div> 
            <ul>
              {/* Отображаем список избранных страниц */}
              {(bookmarkedPages[bookKey] || []).map((pageIndex) => (
                <li key={pageIndex} onClick={() => showPage(bookKey, pageIndex)}>
                  Страница {pageIndex}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BookmarkedPages;
