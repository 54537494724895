import React from 'react';
import '../App.css';
import BackButton from './BackButton'; // компонент кнопки назад
import SEO from './SEO';
import ArrowBack from './ArrowBack';

const FaqPage = () => {

  const handleCopyText = () => {
    const textToCopy = "5469 3801 4381 8747";
    const tempTextarea = document.createElement('textarea');
    tempTextarea.value = textToCopy;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextarea);
    alert("Текст скопирован: " + textToCopy);
  };

  const handleMailClick = () => {
    window.location.href = 'mailto:xonerull@gmail.com';
  };

  const handleTelegramClick = () => {
    window.location.href = 'tg://resolve?domain=wizard_nix';
  };

  return (
    <div className="content">
            <ArrowBack />
      <SEO
        title="О разработчике" 
        description="О разработчике Познай себя" 
        keywords="Фильмы самопознания, философские фильмы, фильмы со смыслом, Иерархия, Учителя, Живая Этика, Агни Йога, Познай себя" 
      />
      <h1>Контакты</h1>
      <p>Хотите поддержать проект?</p>
      <p>Перевод на карту Сбербанк:</p>
      <p id="copy">5469 3801 4381 8747
        <button onClick={handleCopyText} className="copy-button">
          <img src="./img/copy.svg" className="icon-img copy" alt="копировать" title="Скопировать" />
        </button>
      </p>
      <div className="divider"></div>
      <p>Вопрос или предложение - нажмите на кнопку и напишите нам на почту.</p>
      <div className='center'>
      <button onClick={handleMailClick} className="gradient-button-faq ">
        Письмо на почту
      </button>
      </div>
      <p>Связаться в telegram</p>
      <div className="center">
        <button onClick={handleTelegramClick} className="telegram">
          <img src="./img/tel.svg" alt="Telegram" className="icon-img" />
        </button>
      </div>
      <div className="divider"></div>

    </div>
  );
};

export default FaqPage;
