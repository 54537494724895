import React from 'react';

export default function AudioProgressBar({ duration, currentProgress, buffered, ...rest }) {
  const progressBarWidth = isNaN(currentProgress / duration) ? 0 : (currentProgress / duration) * 100;
  const bufferedWidth = isNaN(buffered / duration) ? 0 : (buffered / duration) * 100;

  return (
<div className="audio-progress-container">
  <div className="audio-progress-background"></div>
  <div className="audio-progress-buffered" style={{ width: `${bufferedWidth}%` }}></div>
  <div className="audio-progress-progress" style={{ width: `${progressBarWidth}%` }}></div>
  <input
    type="range"
    min={0}
    max={duration || 0}
    step="any"
    value={currentProgress}
    className="audio-progress-input"
    {...rest}
  />
</div>

  );
}
