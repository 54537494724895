//рабочая версия без избранного
// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import ArrowBack from './ArrowBack';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import BookActions from './BookActions';

// const BookDetailPage = () => {
//   const { imageName } = useParams();
//   const [bookData, setBookData] = useState(null);
//   const [currentPageIndex, setCurrentPageIndex] = useState(null);
//   const [startX, setStartX] = useState(0);
//   const [startY, setStartY] = useState(0);
//   const minSwipeDistance = 50;
//   const [isBookmarked, setIsBookmarked] = useState(false);

//   useEffect(() => {
//     const fetchBookData = async () => {
//       try {
//         const response = await fetch(`/books/${imageName}.json`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch book data');
//         }
//         const data = await response.json();
//         setBookData(data);
//         setCurrentPageIndex(1); // Устанавливаем индекс первой страницы после загрузки данных
//       } catch (error) {
//         console.error('Error reading book.json:', error);
//       }
//     };
//     fetchBookData();
//   }, [imageName]);

//   useEffect(() => {
//     const storedBookmarks = localStorage.getItem('bookmarks');
//     const bookmarks = storedBookmarks ? JSON.parse(storedBookmarks) : [];
//     if (bookmarks.includes(imageName)) {
//       setIsBookmarked(true);
//     }
//   }, [imageName]);

//   const handleBookmark = () => {
//     const storedBookmarks = localStorage.getItem('bookmarks');
//     let bookmarks = storedBookmarks ? JSON.parse(storedBookmarks) : [];

//     if (isBookmarked) {
//       bookmarks = bookmarks.filter((bookmark) => bookmark !== imageName);
//     } else {
//       bookmarks.push(imageName);
//     }

//     localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
//     setIsBookmarked(!isBookmarked);
//   };

//   const handleShare = () => {
//     if (navigator.share) {
//       navigator.share({
//         title: bookData.title,
//         text: `Check out ${bookData.title} - ${bookData.description}`,
//         url: window.location.href,
//       })
//       .then(() => console.log('Shared successfully'))
//       .catch((error) => console.error('Error sharing:', error));
//     } else {
//       console.log('Sharing not supported');
//     }
//   };

//   const showPage = (pageIndex) => {
//     setCurrentPageIndex(pageIndex); // Устанавливаем текущий индекс страницы
//   };

//   const goBackToList = () => {
//     setCurrentPageIndex(null); // Возвращаемся к списку страниц при нажатии кнопки назад
//   };

//   const touchStart = (event) => {
//     setStartX(event.touches[0].pageX);
//     setStartY(event.touches[0].pageY);
//   };

//   const touchMove = (event) => {
//     const deltaX = event.touches[0].pageX - startX;
//     const deltaY = event.touches[0].pageY - startY;
    
//     if (Math.abs(deltaX) > Math.abs(deltaY)) {
//       // Если можно отменить действие по умолчанию, то вызываем preventDefault
//       if (event.cancelable) {
//         event.preventDefault();
//       }
//     }
//   };
  

//   const touchEnd = (event) => {
//     const deltaX = event.changedTouches[0].pageX - startX;
//     if (Math.abs(deltaX) > minSwipeDistance) {
//       if (deltaX > 0) {
//         // Swipe right
//         showPreviousPage();
//       } else {
//         // Swipe left
//         showNextPage();
//       }
//     }
//   };

//   const showNextPage = () => {
//     if (currentPageIndex < bookData.pages.length) {
//       setCurrentPageIndex(currentPageIndex + 1); // Увеличиваем индекс страницы на 1
//     }
//   };

//   const showPreviousPage = () => {
//     if (currentPageIndex > 1) {
//       setCurrentPageIndex(currentPageIndex - 1); // Уменьшаем индекс страницы на 1
//     }
//   };

//   if (!bookData) {
//     return <div>Загрузка...</div>;
//   }

//   return (
//     <div className="book-container">
      
//       {!currentPageIndex && (
//         <div className="page-list">
//           <ArrowBack />
          
//           <ul id="page-list-ul">
//             {bookData.pages.map((page, index) => (
//               <li key={index} onClick={() => showPage(index + 1)}>
//                 {page.title}
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//       {currentPageIndex !== null && (
//         <div className="full-screen-content"
//           onTouchStart={touchStart}
//           onTouchMove={touchMove}
//           onTouchEnd={touchEnd}>
//           <div>
//             <div className='header-action'>
//           <button id="back-button" onClick={goBackToList} className="back-button">
//               <i className="fa-solid fa-chevron-left"></i>
//             </button>
//             <BookActions onShare={handleShare} onBookmark={handleBookmark} isBookmarked={isBookmarked} />
//           </div>
//           </div>
//           <div className="page-content">
//             <p dangerouslySetInnerHTML={{ __html: `${bookData.pages[currentPageIndex - 1].content.replace(/\n/g, '<br>')}` }}></p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default BookDetailPage;


// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import ArrowBack from './ArrowBack';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import BookActions from './BookActions';

// const BookDetailPage = () => {
//   const { imageName } = useParams();
//   const [bookData, setBookData] = useState(null);
//   const [currentPageIndex, setCurrentPageIndex] = useState(null);
//   const [startX, setStartX] = useState(0);
//   const [startY, setStartY] = useState(0);
//   const minSwipeDistance = 50;
//   const [bookmarkedPages, setBookmarkedPages] = useState({});

//   useEffect(() => {
//     const fetchBookData = async () => {
//       try {
//         const response = await fetch(`/books/${imageName}.json`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch book data');
//         }
//         const data = await response.json();
//         setBookData(data);
//         setCurrentPageIndex(1); // Устанавливаем индекс первой страницы после загрузки данных
//       } catch (error) {
//         console.error('Error reading book.json:', error);
//       }
//     };
//     fetchBookData();
//   }, [imageName]);

//   useEffect(() => {
//     const storedBookmarks = localStorage.getItem('bookmarks');
//     let bookmarks = storedBookmarks ? JSON.parse(storedBookmarks) : {};
//     setBookmarkedPages(bookmarks);
//   }, [imageName]);

//   const handleBookmark = (pageIndex) => {
//     const bookmarkedPages = [...bookmarkedPages, pageIndex];
//     const bookmarkData = { pages: bookmarkedPages };
//     localStorage.setItem('bookmarkedPages', JSON.stringify(bookmarkData));
//     const storedBookmarks = localStorage.getItem('bookmarks');
//     let bookmarks = storedBookmarks ? JSON.parse(storedBookmarks) : {};

//     if (!bookmarks[imageName]) {
//       bookmarks[imageName] = [];
//     }

//     const pageIndexes = bookmarks[imageName];
//     if (pageIndexes.includes(pageIndex)) {
//       pageIndexes.splice(pageIndexes.indexOf(pageIndex), 1);
//     } else {
//       pageIndexes.push(pageIndex);
//     }

//     localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
//     setBookmarkedPages(bookmarks);
//   };

//   const handleShare = () => {
//     if (navigator.share) {
//       navigator.share({
//         title: bookData.title,
//         text: `Check out ${bookData.title} - ${bookData.description}`,
//         url: window.location.href,
//       })
//       .then(() => console.log('Shared successfully'))
//       .catch((error) => console.error('Error sharing:', error));
//     } else {
//       console.log('Sharing not supported');
//     }
//   };

//   const showPage = (pageIndex) => {
//     setCurrentPageIndex(pageIndex); // Устанавливаем текущий индекс страницы
//   };

//   const goBackToList = () => {
//     setCurrentPageIndex(null); // Возвращаемся к списку страниц при нажатии кнопки назад
//   };

//   const touchStart = (event) => {
//     setStartX(event.touches[0].pageX);
//     setStartY(event.touches[0].pageY);
//   };

//   const touchMove = (event) => {
//     const deltaX = event.touches[0].pageX - startX;
//     const deltaY = event.touches[0].pageY - startY;
    
//     if (Math.abs(deltaX) > Math.abs(deltaY)) {
//       // Если можно отменить действие по умолчанию, то вызываем preventDefault
//       if (event.cancelable) {
//         event.preventDefault();
//       }
//     }
//   };
  

//   const touchEnd = (event) => {
//     const deltaX = event.changedTouches[0].pageX - startX;
//     if (Math.abs(deltaX) > minSwipeDistance) {
//       if (deltaX > 0) {
//         // Swipe right
//         showPreviousPage();
//       } else {
//         // Swipe left
//         showNextPage();
//       }
//     }
//   };

//   const showNextPage = () => {
//     if (currentPageIndex < bookData.pages.length) {
//       setCurrentPageIndex(currentPageIndex + 1); // Увеличиваем индекс страницы на 1
//     }
//   };

//   const showPreviousPage = () => {
//     if (currentPageIndex > 1) {
//       setCurrentPageIndex(currentPageIndex - 1); // Уменьшаем индекс страницы на 1
//     }
//   };

//   const isBookmarked = (pageIndex) => {
//     return bookmarkedPages[imageName] && bookmarkedPages[imageName].includes(pageIndex);
//   };

//   return (
//     <div className="book-container">
//       {!currentPageIndex && (
//         <div className="page-list">
//           <ArrowBack />
//           {bookData && ( // Add this conditional statement
//             <ul id="page-list-ul">
//               {bookData.pages.map((page, index) => (
//                 <li key={index} onClick={() => showPage(index + 1)}>
//                   {page.title}
//                 </li>
//               ))}
//             </ul>
//           )}
//         </div>
//       )}
//       {currentPageIndex !== null && (
//         <div className="full-screen-content"
//           onTouchStart={touchStart}
//           onTouchMove={touchMove}
//           onTouchEnd={touchEnd}>
//           <div>
//             <div className='header-action'>
//               <button id="back-button" onClick={goBackToList} className="back-button">
//                 <i className="fa-solid fa-chevron-left"></i>
//               </button>
//               <BookActions onShare={handleShare} onBookmark={(pageIndex) => handleBookmark(pageIndex)} isBookmarked={(pageIndex) => isBookmarked(pageIndex)} />
//             </div>
//           </div>
//           {bookData && ( // Add this conditional statement
//             <div className="page-content">
//               <p dangerouslySetInnerHTML={{ __html: `${bookData.pages[currentPageIndex - 1].content.replace(/\n/g, '<br>')}` }}></p>
//             </div>
//           )}
//         </div>
//       )}
//       <BookmarkedPages imageName={imageName} bookmarkedPages={bookmarkedPages} />
//     </div>
//   );
// }

// const BookmarkedPages = ({ imageName, bookmarkedPages }) => {
//   const [bookmarkedPagesList, setBookmarkedPagesList] = useState([]);

//   useEffect(() => {
//     if (bookmarkedPages[imageName]) {
//       setBookmarkedPagesList(bookmarkedPages[imageName]);
//     }
//   }, [bookmarkedPages, imageName]);

//   return (
//     <div>
//       <h2>Bookmarked Pages</h2>
//       <ul>
//         {bookmarkedPagesList.map((pageIndex) => (
//           <li key={pageIndex}>Page {pageIndex}</li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default BookDetailPage;

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import ArrowBack from './ArrowBack';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import BookActions from './BookActions';
// import BookmarkedPages from './BookmarkedPages';

// const BookDetailPage = () => {
//   const { imageName } = useParams();
//   const [bookData, setBookData] = useState(null);
//   const [currentPageIndex, setCurrentPageIndex] = useState(null);
//   const [startX, setStartX] = useState(0);
//   const [startY, setStartY] = useState(0);
//   const minSwipeDistance = 50;
//   const [bookmarkedPages, setBookmarkedPages] = useState(() => {
//     const storedBookmarks = localStorage.getItem('bookmarks');
//     return storedBookmarks ? JSON.parse(storedBookmarks) : {};
//   });

//   useEffect(() => {
//     const fetchBookData = async () => {
//       try {
//         const response = await fetch(`/books/${imageName}.json`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch book data');
//         }
//         const data = await response.json();
//         setBookData(data);
//         setCurrentPageIndex(1); // Устанавливаем индекс первой страницы после загрузки данных
//       } catch (error) {
//         console.error('Error reading book.json:', error);
//       }
//     };
//     fetchBookData();
//   }, [imageName]);

//   useEffect(() => {
//     const storedBookmarks = localStorage.getItem('bookmarks');
//     let bookmarks = storedBookmarks ? JSON.parse(storedBookmarks) : {};
//     setBookmarkedPages(bookmarks);
//   }, [imageName]);

//   const handleBookmark = (pageIndex) => {
//     setBookmarkedPages((prevBookmarkedPages) => {
//       const newBookmarkedPages = { ...prevBookmarkedPages };
//       if (!newBookmarkedPages[imageName]) {
//         newBookmarkedPages[imageName] = [];
//       }

//       const pageIndexes = newBookmarkedPages[imageName];
//       if (pageIndexes.includes(pageIndex)) {
//         pageIndexes.splice(pageIndexes.indexOf(pageIndex), 1);
//       } else {
//         pageIndexes.push(pageIndex);
//       }

//       localStorage.setItem('bookmarks', JSON.stringify(newBookmarkedPages));
//       return newBookmarkedPages;
//     });
//   };

//   const handleShare = () => {
//     if (navigator.share) {
//       navigator.share({
//         title: bookData.title,
//         text: `Check out ${bookData.title} - ${bookData.description}`,
//         url: window.location.href,
//       })
//         .then(() => console.log('Shared successfully'))
//         .catch((error) => console.error('Error sharing:', error));
//     } else {
//       console.log('Sharing not supported');
//     }
//   };

//   const showPage = (pageIndex) => {
//     setCurrentPageIndex(pageIndex); // Устанавливаем текущий индекс страницы
//   };

//   const goBackToList = () => {
//     setCurrentPageIndex(null); // Возвращаемся к списку страниц при нажатии кнопки назад
//   };

//   const touchStart = (event) => {
//     setStartX(event.touches[0].pageX);
//     setStartY(event.touches[0].pageY);
//   };

//   const touchMove = (event) => {
//     const deltaX = event.touches[0].pageX - startX;
//     const deltaY = event.touches[0].pageY - startY;

//     if (Math.abs(deltaX) > Math.abs(deltaY)) {
//       // Если можно отменить действие по умолчанию, то вызываем preventDefault
//       if (event.cancelable) {
//         event.preventDefault();
//       }
//     }
//   };

//   const touchEnd = (event) => {
//     const deltaX = event.changedTouches[0].pageX - startX;
//     if (Math.abs(deltaX) > minSwipeDistance) {
//       if (deltaX > 0) {
//         // Swipe right
//         showPreviousPage();
//       } else {
//         // Swipe left
//         showNextPage();
//       }
//     }
//   };

//   const showNextPage = () => {
//     if (currentPageIndex < bookData.pages.length) {
//       setCurrentPageIndex(currentPageIndex + 1); // Увеличиваем индекс страницы на 1
//     }
//   };

//   const showPreviousPage = () => {
//     if (currentPageIndex > 1) {
//       setCurrentPageIndex(currentPageIndex - 1); // Уменьшаем индекс страницы на 1
//     }
//   };

//   const isBookmarked = (pageIndex) => {
//     return bookmarkedPages[imageName] && bookmarkedPages[imageName].includes(pageIndex);
//   };

//   return (
//     <div className="book-container">
//       {!currentPageIndex && (
//         <div className="page-list">
//           <ArrowBack />
//           {bookData && (
//             <ul id="page-list-ul">
//               {bookData.pages.map((page, index) => (
//                 <li key={index} onClick={() => showPage(index + 1)}>
//                   {page.title}
//                 </li>
//               ))}
//             </ul>
//           )}
          
//         </div>
//       )}
//       {currentPageIndex !== null && (
//         <div className="full-screen-content"
//           onTouchStart={touchStart}
//           onTouchMove={touchMove}
//           onTouchEnd={touchEnd}>
//           <div>
//             <div className='header-action'>
//               <button id="back-button" onClick={goBackToList} className="back-button">
//                 <i className="fa-solid fa-chevron-left glowing-icon"></i>
//               </button>
//               <BookActions 
//                 onShare={handleShare} 
//                 onBookmark={() => handleBookmark(currentPageIndex)} 
//                 isBookmarked={isBookmarked(currentPageIndex)} 
//               />
//             </div>
//           </div>
//           <div className="page-content">
//             <p
//               dangerouslySetInnerHTML={{
//                 __html: `${bookData.pages[currentPageIndex - 1].content.replace(/\n/g, '<br>')}`,
//               }}
//             ></p>
//           </div>
          
//         </div>
//       )}
//     </div>
//   );
// };

// export default BookDetailPage;
  
// версия рабочая, но избранное не работает

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBack from './ArrowBack';
import '@fortawesome/fontawesome-free/css/all.min.css';
import BookActions from './BookActions';

const BookDetailPage = () => {
  const { imageName } = useParams();
  const navigate = useNavigate();
  const [bookData, setBookData] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(null);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const minSwipeDistance = 50;
  const [bookmarkedPages, setBookmarkedPages] = useState(() => {
    const storedBookmarks = localStorage.getItem('bookmarks');
    return storedBookmarks ? JSON.parse(storedBookmarks) : {};
  });

  useEffect(() => {
    const fetchBookData = async () => {
      try {
        const response = await fetch(`/books/${imageName}.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch book data');
        }
        const data = await response.json();
        setBookData(data);
        setCurrentPageIndex(1); // Устанавливаем индекс первой страницы после загрузки данных
      } catch (error) {
        console.error('Error reading book.json:', error);
      }
    };
    fetchBookData();
  }, [imageName]);

  useEffect(() => {
    const storedBookmarks = localStorage.getItem('bookmarks');
    let bookmarks = storedBookmarks ? JSON.parse(storedBookmarks) : {};
    setBookmarkedPages(bookmarks);
  }, [imageName]);

  const handleBookmark = (pageIndex) => {
    setBookmarkedPages((prevBookmarkedPages) => {
      const newBookmarkedPages = { ...prevBookmarkedPages };
      if (!newBookmarkedPages[imageName]) {
        newBookmarkedPages[imageName] = [];
      }

      const pageIndexes = newBookmarkedPages[imageName];
      if (pageIndexes.includes(pageIndex)) {
        pageIndexes.splice(pageIndexes.indexOf(pageIndex), 1);
      } else {
        pageIndexes.push(pageIndex);
      }

      localStorage.setItem('bookmarks', JSON.stringify(newBookmarkedPages));
      return newBookmarkedPages;
    });
  };

  const handleShare = () => {
    if (navigator.share) {
      const currentUrl = window.location.href; // Получаем текущий URL страницы
      const title = bookData?.title || 'Книга'; // Заголовок страницы
      const pageContentElement = document.querySelector('.page-content'); // Получаем элемент с классом .page-content
      const pageContentText = pageContentElement ? pageContentElement.innerText : ''; // Извлекаем текстовое содержимое
  
      navigator
        .share({
          title: title,
          text: pageContentText || 'Что-то пошло не так!', // Шарим текст страницы
        })
        .then(() => console.log('Вы успешно поделились'))
        .catch((error) => console.error('Ошибка:', error));
    } else {
      // Фоллбэк на случай, если шаринг не поддерживается
      alert('Не получилось поделиться :( Попробуйте скопировать вручную/ю');
    }
  };

  const showPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex); // Устанавливаем текущий индекс страницы
  };

  const goBackToList = () => {
    setCurrentPageIndex(null); // Возвращаемся к списку страниц при нажатии кнопки назад
  };

  const touchStart = (event) => {
    setStartX(event.touches[0].pageX);
    setStartY(event.touches[0].pageY);
  };

  const touchMove = (event) => {
    const deltaX = event.touches[0].pageX - startX;
    const deltaY = event.touches[0].pageY - startY;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      if (event.cancelable) {
        event.preventDefault();
      }
    }
  };

  const touchEnd = (event) => {
    const deltaX = event.changedTouches[0].pageX - startX;
    if (Math.abs(deltaX) > minSwipeDistance) {
      if (deltaX > 0) {
        showPreviousPage();
      } else {
        showNextPage();
      }
    }
  };

  const showNextPage = () => {
    if (currentPageIndex < bookData.pages.length) {
      setCurrentPageIndex(currentPageIndex + 1); // Увеличиваем индекс страницы на 1
    }
  };

  const showPreviousPage = () => {
    if (currentPageIndex > 1) {
      setCurrentPageIndex(currentPageIndex - 1); // Уменьшаем индекс страницы на 1
    }
  };

  const isBookmarked = (pageIndex) => {
    return bookmarkedPages[imageName] && bookmarkedPages[imageName].includes(pageIndex);
  };

  return (
    <div className="book-container">
      {!currentPageIndex && (
        <div className="page-list">
          <ArrowBack />
          {bookData && (
            <ul id="page-list-ul">
              {bookData.pages.map((page, index) => (
                <li key={index} onClick={() => showPage(index + 1)}>
                  {page.title}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      {currentPageIndex !== null && (
        <div className="full-screen-content"
          onTouchStart={touchStart}
          onTouchMove={touchMove}
          onTouchEnd={touchEnd}>
          <div>
            <div className='header-action'>
              <button id="back-button" onClick={goBackToList} className="back-button">
                <i className="fa-solid fa-chevron-left glowing-icon"></i>
              </button>
              <BookActions 
                onShare={handleShare} 
                onBookmark={() => handleBookmark(currentPageIndex)} 
                isBookmarked={isBookmarked(currentPageIndex)} 
              />
            </div>
          </div>
          <div className="page-content">
            <p
              dangerouslySetInnerHTML={{
                __html: `${bookData.pages[currentPageIndex - 1].content.replace(/\n/g, '<br>')}`,
              }}
            ></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookDetailPage;

// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import ArrowBack from './ArrowBack';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import BookActions from './BookActions';

// const BookDetailPage = () => {
//   const { imageName, pageIndex } = useParams(); // Добавляем pageIndex из параметров
//   const navigate = useNavigate();
//   const [bookData, setBookData] = useState(null);
//   const [currentPageIndex, setCurrentPageIndex] = useState(pageIndex ? parseInt(pageIndex) : 1);
//   const [startX, setStartX] = useState(0);
//   const [startY, setStartY] = useState(0);
//   const minSwipeDistance = 50;
//   const [bookmarkedPages, setBookmarkedPages] = useState(() => {
//     const storedBookmarks = localStorage.getItem('bookmarks');
//     return storedBookmarks ? JSON.parse(storedBookmarks) : {};
//   });

//   useEffect(() => {
//     const fetchBookData = async () => {
//       try {
//         const response = await fetch(`/books/${imageName}.json`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch book data');
//         }
//         const data = await response.json();
//         setBookData(data);
//       } catch (error) {
//         console.error('Error reading book.json:', error);
//       }
//     };
//     fetchBookData();
//   }, [imageName]);

//   useEffect(() => {
//     const storedBookmarks = localStorage.getItem('bookmarks');
//     const bookmarks = storedBookmarks ? JSON.parse(storedBookmarks) : {};
//     setBookmarkedPages(bookmarks);
//   }, [imageName]);

//   const handleBookmark = (pageIndex) => {
//     setBookmarkedPages((prevBookmarkedPages) => {
//       const newBookmarkedPages = { ...prevBookmarkedPages };
//       if (!newBookmarkedPages[imageName]) {
//         newBookmarkedPages[imageName] = [];
//       }

//       const pageIndexes = newBookmarkedPages[imageName];
//       if (pageIndexes.includes(pageIndex)) {
//         pageIndexes.splice(pageIndexes.indexOf(pageIndex), 1);
//       } else {
//         pageIndexes.push(pageIndex);
//       }

//       localStorage.setItem('bookmarks', JSON.stringify(newBookmarkedPages));
//       return newBookmarkedPages;
//     });
//   };

//   const handleShare = () => {
//     if (navigator.share) {
//       navigator.share({
//         title: bookData.title,
//         text: `Check out ${bookData.title} - ${bookData.description}`,
//         url: window.location.href,
//       })
//         .then(() => console.log('Shared successfully'))
//         .catch((error) => console.error('Error sharing:', error));
//     } else {
//       console.log('Sharing not supported');
//     }
//   };

//   const showPage = (pageIndex) => {
//     setCurrentPageIndex(pageIndex);
//   };

//   const goBackToList = () => {
//     navigate('/'); // Вернуться к главной странице
//   };

//   const touchStart = (event) => {
//     setStartX(event.touches[0].pageX);
//     setStartY(event.touches[0].pageY);
//   };

//   const touchMove = (event) => {
//     const deltaX = event.touches[0].pageX - startX;
//     const deltaY = event.touches[0].pageY - startY;

//     if (Math.abs(deltaX) > Math.abs(deltaY)) {
//       if (event.cancelable) {
//         event.preventDefault();
//       }
//     }
//   };

//   const touchEnd = (event) => {
//     const deltaX = event.changedTouches[0].pageX - startX;
//     if (Math.abs(deltaX) > minSwipeDistance) {
//       if (deltaX > 0) {
//         showPreviousPage();
//       } else {
//         showNextPage();
//       }
//     }
//   };

//   const showNextPage = () => {
//     if (currentPageIndex < bookData.pages.length) {
//       setCurrentPageIndex(currentPageIndex + 1);
//     }
//   };

//   const showPreviousPage = () => {
//     if (currentPageIndex > 1) {
//       setCurrentPageIndex(currentPageIndex - 1);
//     }
//   };

//   const isBookmarked = (pageIndex) => {
//     return bookmarkedPages[imageName] && bookmarkedPages[imageName].includes(pageIndex);
//   };

//   return (
//     <div className="book-container">
//       {bookData && currentPageIndex !== null && (
//         <div className="full-screen-content"
//           onTouchStart={touchStart}
//           onTouchMove={touchMove}
//           onTouchEnd={touchEnd}>
//           <div>
//             <div className='header-action'>
//               <button id="back-button" onClick={goBackToList} className="back-button">
//                 <i className="fa-solid fa-chevron-left glowing-icon"></i>
//               </button>
//               <BookActions 
//                 onShare={handleShare} 
//                 onBookmark={() => handleBookmark(currentPageIndex)} 
//                 isBookmarked={isBookmarked(currentPageIndex)} 
//               />
//             </div>
//           </div>
//           <div className="page-content">
//             <p
//               dangerouslySetInnerHTML={{
//                 __html: `${bookData.pages[currentPageIndex - 1].content.replace(/\n/g, '<br>')}`,
//               }}
//             ></p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default BookDetailPage;
