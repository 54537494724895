import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import SEO from './SEO';
const HomePage = () => {
  return (
    <div>
      <SEO
        title="Живая Этика, приложение Познай себя" 
        description="Книги, фильмы для самопознания, познания себя. Живая Этика" 
        keywords="Иерархия, Учителя, Живая Этика, Агни Йога, Познай себя" 
      />
      <Navigation />
      <Footer />
    </div>
  );
};

export default HomePage;
