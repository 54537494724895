import React from 'react';
import '../Style/index.css';

export default function VolumeInput({ volume, onVolumeChange }) {
  return (
    <input
      type="range"
      min={0}
      max={1}
      step={0.01}
      value={volume}
      onChange={(e) => onVolumeChange(parseFloat(e.target.value))}
      className="volume-input"
    />
  );
}
