
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ArrowBack from './ArrowBack';
import '../App.css';

const booksData = [
    { id: 1001, title: "Листы Сада Мории. Зов.", imageName: "1" },
    { id: 1002, title: "Листы Сада Мории. Озарение", imageName: "2" },
    { id: 1003, title: "Община", imageName: "3" },
    { id: 1004, title: "Агни Йога", imageName: "4" },
    { id: 1005, title: "Беспредельность. Часть I", imageName: "5" },
    { id: 1006, title: "Беспредельность. Часть II", imageName: "6" },
    { id: 1007, title: "Иерархия", imageName: "7" },
    { id: 1008, title: "Сердце", imageName: "8" },
    { id: 1009, title: "Мир Огненный. Часть I", imageName: "9" },
    { id: 1010, title: "Мир Огненный. Часть II", imageName: "10" },
    { id: 1011, title: "Мир Огненный. Часть III", imageName: "11" },
    { id: 1012, title: "Аум", imageName: "12" },
    { id: 1013, title: "Братство", imageName: "13" },
    { id: 1014, title: "Надземное. (3 книги)", imageName: "14" },
  ];
  
  const Search = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [books, setBooks] = useState([]);
    const [filteredBooks, setFilteredBooks] = useState([]);
    const location = useLocation();
  
    useEffect(() => {
      const loadBooks = async () => {
        const imageNames = booksData.map(book => book.imageName); // Используем imageName из массива booksData
        const bookData = await Promise.all(
          imageNames.map(async (imageName) => {
            const response = await fetch(`/books/${imageName}.json`);
            if (!response.ok) {
              throw new Error(`Could not fetch ${imageName}.json`);
            }
            return response.json();
          })
        );
  
        setBooks(bookData);
      };
  
      loadBooks().catch(console.error);
    }, []);
  
    const handleSearch = () => {
      const results = books.flatMap((book, index) =>
        book.pages
          .filter(page => 
            page.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
            page.content.toLowerCase().includes(searchTerm.toLowerCase()))
          .map(page => ({
            bookTitle: booksData[index].title, // Получаем заголовок книги из booksData
            pageNumber: page.number,
            pageTitle: highlightText(page.title, searchTerm),
            pageContent: highlightText(page.content, searchTerm),
          }))
      );
      setFilteredBooks(results);
    };
  
    const highlightText = (text, term) => {
      if (!term) return text;
      const regex = new RegExp(`(${term})`, 'gi');
      return text.split(regex).map((part, index) => 
        regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow',color: 'black', padding:0, }}>{part}</span> : part
      );
    };
  
    // Условие для отображения компонента
    if (location.pathname.includes('/books/')) {
      return null; // Не рендерим компонент поиска на страницах деталей книги
    }
  return (

    <div className='content'>
        <ArrowBack />
        <h1>Введите фразу для поиска</h1>
        <input
        type="text"
        placeholder="Поиск по книгам..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
      <button className="search_btn" onClick={handleSearch}>Найти</button>
      <div>
        {filteredBooks.length > 0 ? (
          filteredBooks.map((result, index) => (
            <div key={index}>
              <h3>{result.bookTitle} - Страница {result.pageNumber}</h3>
              <p>{result.pageContent}</p>
            </div>
          ))
        ) : (
          <p>Пока ничего не найдено</p>
        )}
      </div>
    </div>
  );
};

export default Search;
