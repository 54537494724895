import React from 'react';

export default function IconButton({ onClick, disabled, children, ...rest }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className="rounded"
      {...rest}
    >
      {children}
    </button>
  );
}
