import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  return (
    <nav>
      <Link to="/ierarh" className="menu-item">
        <div className="icon-background">
          <div className="icon"> 
            <img src="./img/Agni.png" className="img-size" alt="Учителя" />
          </div>
        </div>
        <span>Портреты Учителей</span>
      </Link>
      <div className="divider"></div>
      <Link to="/books" className="menu-item">
        <div className="icon-background">
          <div className="icon"> 
            <img src="./img/book.svg" className="img-size" alt="Книги" />
          </div>
        </div>
        <span>Книги Живая Этика</span>
      </Link>


      <div className="divider"></div>
      <Link to="/films" className="menu-item">
        <div className="icon-background">
          <div className="icon"> 
            <img src="./img/play.svg" className="img-size" alt="Фильмы" />
          </div>
        </div>
        <span>Фильмы самопознания</span>
      </Link>
      <div className="divider"></div>
      <Link to="/audio" className="menu-item">
        <div className="icon-background">
          <div className="icon"> 
            <img src="./img/music.svg" className="img-size" alt="Аудио" />
          </div>
        </div>
        <span>Аудио-фильмы самопознания</span>
      </Link>
{/*       
      <div className="divider"></div>
      <Link to="/favorites" className="menu-item">
        <div className="icon-background">
          <div className="icon"> 
            <img src="./img/fav.svg" className="img-size" alt="" />
          </div>
        </div>
        <span>Избранное</span>
      </Link> */}
      <div className="divider"></div>
      <Link to="/faq" className="menu-item">
        <div className="icon-background">
          <div className="icon"> 
            <img src="./img/q.svg" className="img-size" alt="" />
          </div>
        </div>
        <span>FAQ/Контакты</span>
      </Link>

    </nav>
  );
};

export default Navigation;
