import React from 'react';
import { Link } from 'react-router-dom';
import ArrowBack from './ArrowBack';
import '../App.css';
import SEO from './SEO';


const books = [
  { id: 1001, title: "Листы Сада Мории. Зов.", imageName: "1"},
  { id: 1002, title: "Листы Сада Мории. Озарение", imageName: "2"},
  { id: 1003, title: "Община", imageName: "3" },
  { id: 1004, title: "Агни Йога", imageName: "4" },
  { id: 1005, title: "Беспредельность. Часть I", imageName: "5" },
  { id: 1006, title: "Беспредельность. Часть II", imageName: "6" },
  { id: 1007, title: "Иерархия", imageName: "7" },
  { id: 1008, title: "Сердце", imageName: "8" },
  { id: 1009, title: "Мир Огненный. Часть I", imageName: "9" },
  { id: 1010, title: "Мир Огненный. Часть II", imageName: "10" },
  { id: 1011, title: "Мир Огненный. Часть III", imageName: "11" },
  { id: 1012, title: "Аум", imageName: "12" },
  { id: 1013, title: "Братство", imageName: "13" },
  { id: 1014, title: "Надземное. (3 книги)", imageName: "14" },
];
const BooksPage = () => {
  return (
    <div className="content">

      <ArrowBack />
      <SEO
        title="Книги самопознания, книги Агни Йога, книги Живая Этика" 
        description="Книги Живая Этика. Рерих" 
        keywords="Книги Живая Этика, книги Агни Йога, Иерархия, Учителя, Живая Этика, Агни Йога, Познай себя" 
      />
        <Link to="/search" className="menu-item center">
        <div className="icon-background">
          <div className="icon"> 
            <img src="./img/search.svg" className="img-size" alt="" />
          </div>
        </div>
        <span>Поиск по книгам</span>
      </Link>
      <div className="divider"></div>
      <div className="film-list">
        {books.map(book => (
          <Link key={book.id} to={`/books/${book.imageName}`} className="film">
            <img src={`./img/${book.imageName}.jpeg`} alt={book.title} />
            <p>{book.title}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BooksPage;

