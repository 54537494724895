import React from 'react';
import ArrowBack from './ArrowBack';
import Accordion from './Accordion';
import SEO from './SEO';

const ierarhs = [
  { id: 100, name: "Матерь Мира", imageName: "mother"},
  { id: 101, name:  "Мать Мария", imageName: "maria_s"},
  { id: 103, name: "Иисус Христос", imageName: "jesus_s"},
  { id: 104, name: "Махатма Мориа", imageName: "moria_s"},
  { id: 102, name:  "Урусвати, Е. И. Рерих", imageName: "elena-r"},
  { id: 105, name: "Сен-Жермен", imageName: "sen-jermen"},
  { id: 106, name: "Кут Хуми", imageName: "koot-hoomi"},
  { id: 107, name: "Картина Fiat Rex", imageName: "fiat-rex"},
 
];

const IerarhPage = () => {
  return (
    <div className="content">
      <ArrowBack />
      <SEO
        title="Иерархия" 
        description="Иерарихия Учителей Света, Агни Йога - Живая Этика" 
        keywords="Иерархия, Учителя, Живая Этика, Агни Йога, Познай себя" 
      />
      <div className="ierarh-list">
        {ierarhs.map((ierarh,index) => (
          // <div key={ierarh.id} className="ierarh">
          //   <img src={`./img/${ierarh.imageName}.jpg`} alt={ierarh.name} />
          //   <p>{ierarh.name}</p>
          // </div>
          <Accordion key={ierarh.id} title={ierarh.name} defaultOpen={index === 0}>
            <div className="ierarh">
              <img src={`./img/${ierarh.imageName}.jpg`} alt={ierarh.name} />
              <p>{ierarh.description}</p>
            </div>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default IerarhPage;
