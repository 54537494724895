import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Components/HomePage';
import IerarhPage from './Components/IerarhPage';
import BooksPage from './Components/BooksPage';
import FilmsPage from './Components/FilmsPage';
import FaqPage from './Components/FaqPage';
import BookDetailPage from './Components/BookDetailPage';
import AudioPage from './Components/AudioPage';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import BookmarkedPages from './Components/BookmarkedPages';
import Search from './Components/Search';
import YandexMetrika from './Components/YandexMetrika';

const YOUR_METRIKA_ID = '98613893';
const App = () => {
  return (
    <HelmetProvider>
    <Router>
    <YandexMetrika metrikaId={YOUR_METRIKA_ID} />
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/ierarh" element={<IerarhPage />} />
          <Route path="/books" element={<BooksPage />} />
          <Route path="/books/:imageName" element={<BookDetailPage />} />
          <Route path="/search" element={<Search />} />
          <Route path="/audio" element={<AudioPage />} />
          <Route path="/films" element={<FilmsPage />} />
          {/* <Route path="/favorites" element={<BookmarkedPages />} /> */}
          {/* <Route path="/book/:imageName/:pageIndex?" element={<BookDetailPage />} /> */}
          <Route path="/faq" element={<FaqPage />} />
        </Routes>
      </div>
    </Router>
    </HelmetProvider>
  );
};

export default App;
