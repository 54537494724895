import React, { useRef, useState, useEffect } from 'react';
import '../Style/index.css';
import {
  MdPlayArrow,
  MdPause,
  MdSkipNext,
  MdSkipPrevious,
  MdVolumeUp,
  MdVolumeOff,
} from 'react-icons/md';
import { CgSpinner } from 'react-icons/cg';
import IconButton from './IconButton';
import AudioProgressBar from './AudioProgressBar';
import VolumeInput from './VolumeInput';

function formatDurationDisplay(duration) {
  const min = Math.floor(duration / 60);
  const sec = Math.floor(duration - min * 60);
  return [min, sec].map((n) => (n < 10 ? '0' + n : n)).join(':');
}

export default function AudioPlayer({ currentSong, songCount, songIndex, onNext, onPrev }) {
  const audioRef = useRef(null);

  const [isReady, setIsReady] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currrentProgress, setCurrrentProgress] = useState(0);
  const [buffered, setBuffered] = useState(0);
  const [volume, setVolume] = useState(0.2);
  const [isPlaying, setIsPlaying] = useState(false);

  const durationDisplay = formatDurationDisplay(duration);
  const elapsedDisplay = formatDurationDisplay(currrentProgress);

  useEffect(() => {
    audioRef.current?.pause();
    const timeout = setTimeout(() => {
      audioRef.current?.play();
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [songIndex]);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current?.play();
      setIsPlaying(true);
    }
  };

  const handleBufferProgress = (e) => {
    const audio = e.currentTarget;
    const dur = audio.duration;
    if (dur > 0) {
      for (let i = 0; i < audio.buffered.length; i++) {
        if (audio.buffered.start(audio.buffered.length - 1 - i) < audio.currentTime) {
          const bufferedLength = audio.buffered.end(audio.buffered.length - 1 - i);
          setBuffered(bufferedLength);
          break;
        }
      }
    }
  };

  const handleMuteUnmute = () => {
    if (!audioRef.current) return;
    if (audioRef.current.volume !== 0) {
      audioRef.current.volume = 0;
    } else {
      audioRef.current.volume = 1;
    }
  };

  const handleVolumeChange = (volumeValue) => {
    if (!audioRef.current) return;
    audioRef.current.volume = volumeValue;
    setVolume(volumeValue);
  };

  return (
    <div className="player-container">
      {currentSong && (
        <audio
          ref={audioRef}
          preload="metadata"
          onDurationChange={(e) => setDuration(e.currentTarget.duration)}
          onPlaying={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onEnded={onNext}
          onCanPlay={(e) => {
            e.currentTarget.volume = volume;
            setIsReady(true);
          }}
          onTimeUpdate={(e) => {
            setCurrrentProgress(e.currentTarget.currentTime);
            handleBufferProgress(e);
          }}
          onProgress={handleBufferProgress}
          onVolumeChange={(e) => setVolume(e.currentTarget.volume)}
        >
          <source type="audio/mpeg" src={currentSong.src} />
        </audio>
      )}
      <AudioProgressBar
        duration={duration}
        currentProgress={currrentProgress}
        buffered={buffered}
        onChange={(e) => {
          if (!audioRef.current) return;
          audioRef.current.currentTime = e.currentTarget.valueAsNumber;
          setCurrrentProgress(e.currentTarget.valueAsNumber);
        }}
      />

      <div className="flex flex-col items-center justify-center">
        <div className="text-center">
          <p className="text-title">{currentSong?.title ?? 'Выберите аудио'}</p>
        </div>
      </div>

      <div className="controls-container">
        <span className="time-display">
          {elapsedDisplay} / {durationDisplay}
        </span>
        <div className="controls-buttons">
          {/* <IconButton onClick={onPrev} disabled={songIndex === 0} aria-label="icon-button-secondary">
            <MdSkipPrevious size={24} />
          </IconButton> */}
          <IconButton disabled={!isReady} onClick={togglePlayPause} aria-label={isPlaying ? 'Pause' : 'Play'} className="icon-button-lg">
            {!isReady && currentSong ? <CgSpinner size={24} className="spin" /> : isPlaying ? <MdPause size={30} /> : <MdPlayArrow size={30} />}
          </IconButton>
          {/* <IconButton onClick={onNext} disabled={songIndex === songCount - 1} aria-label="icon-button-secondary">
            <MdSkipNext size={24} />
          </IconButton> */}
        </div>

        <div className="volume-controls">
          <IconButton className="icon-button-secondary icon-button-sm" onClick={handleMuteUnmute} aria-label={volume === 0 ? 'unmute' : 'mute'}>
            {volume === 0 ? <MdVolumeOff size={20} /> : <MdVolumeUp size={20} />}
          </IconButton>
          <VolumeInput className="volume-input" volume={volume} onVolumeChange={handleVolumeChange} />
        </div>
      </div>
    </div>
  );
}
