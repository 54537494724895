import React from 'react';
import '../App.css';

const Footer = () => {
  return (
    <footer>
      
            <img src="./img/heart.svg" className="img-size-heart" alt="Книги" />
      <p className="gradient-text">Сделано в России с Любовью</p>
    </footer>
  );
};

export default Footer;
