// src/Components/BackButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const ArrowBack = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className="left">
      <button onClick={handleBackClick} className="back-button">
        <i className="fa-solid fa-chevron-left"></i>
      </button>
    </div>
  );
};

export default ArrowBack;

