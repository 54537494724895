// // components/BookActions.js
// import React from 'react';
// import '@fortawesome/fontawesome-free/css/all.min.css';

// const BookActions = ({ onShare, onBookmark, isBookmarked }) => {
//   return (
//     <div className="book-actions">
//       <button onClick={onShare} className="action-button">
//         <i className="fa-solid fa-share-alt"></i>
//       </button>
//       <button onClick={onBookmark} className={`action-button ${isBookmarked ? 'bookmarked' : ''}`}>
//         <i className="fa-solid fa-bookmark"></i>
//       </button>
//     </div>
//   );
// };

// export default BookActions;

// import React from 'react';
// import '@fortawesome/fontawesome-free/css/all.min.css';

// const BookActions = ({ onShare, onBookmark, isBookmarked, bookmarkCount }) => {
//   return (
//     <div className="book-actions">
//       <button onClick={onShare} className="action-button">
//         <i className="fa-solid fa-share-alt"></i>
//       </button>
//       <button onClick={onBookmark} className={`action-button ${isBookmarked ? 'bookmarked' : ''}`}>
//         <i className="fa-solid fa-bookmark"></i>
//       </button>
//       <span>{bookmarkCount}</span>
//     </div>
//   );
// };

// export default BookActions;

import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

const BookActions = ({ onShare, onBookmark, isBookmarked, bookmarkCount = 0 }) => {
  return (
    <div className="book-actions">
      <button onClick={onShare} className="action-button" aria-label="Share">
        <i className="fa-solid fa-share-alt"></i>
      </button>
      {/* <button 
        onClick={onBookmark} 
        className={`action-button ${isBookmarked ? 'bookmarked' : 'nobookmarked'}`} 
        aria-label={isBookmarked ? "Remove Bookmark" : "Add Bookmark"}
      >
        <i className={`fa-solid fa-bookmark ${isBookmarked ? '' : '-regular'}`}></i>
      </button>
      <span>{bookmarkCount}</span> */}
    </div>
  );
};

export default BookActions;



