import React, { useState } from 'react';
import BackButton from './BackButton';
import '../Style/index.css';
import '../App.css';
import SEO from './SEO';
import ArrowBack from './ArrowBack';
import { MdPlayArrow, MdPause } from 'react-icons/md';
import AudioPlayer from './AudioPlayer';

const audioFiles = [
  {
    title: "Зов",
    src: "https://au.gnothis.ru/audiofilms/zov.mp3",
    description: "Что сердце бьется\r\n- Ничего не значит,\r\nОно молчит,\r\nЗадавленное суетой и бытом,\r\nВначале нужно оживить его!!!"
  },
  {
    title: "Дольмены счастья",
    src: "https://au.gnothis.ru/audiofilms/2.mp3",
    description: ""
  },
  {
    title: "Чаша Бога",
    src: "https://au.gnothis.ru/audiofilms/3.mp3",
    description: ""
  },
  {
    title: "Путь к Ра",
    src: "https://au.gnothis.ru/audiofilms/4.mp3",
    description: ""
  },
  {
    title: "Бессмертие",
    src: "https://au.gnothis.ru/audiofilms/5.mp3",
    description: ""
  },
  {
    title: "Осознание",
    src: "https://au.gnothis.ru/audiofilms/6.mp3",
    description: ""
  },
  {
    title: "Проникновение",
    src: "https://au.gnothis.ru/audiofilms/7.mp3",
    description: ""
  },
  {
    title: "Озарение",
    src: "https://au.gnothis.ru/audiofilms/8.mp3",
    description: ""
  },
];

export default function App() {
  const [currentSongIndex, setCurrentSongIndex] = useState(-1);

  const currentSong = audioFiles[currentSongIndex];

  return (

    <div className="content">
      <ArrowBack />
      <SEO
        title="Книги самопознания, книги Агни Йога, книги Живая Этика" 
        description="Книги Живая Этика. Рерих" 
        keywords="Книги Живая Этика, книги Агни Йога, Иерархия, Учителя, Живая Этика, Агни Йога, Познай себя" 
      />
      <h1>Аудио-фильмы<br /> "Как путь по звёздам отыскать"</h1>
      <h2>Режиссёр: Олег Рудюк</h2>
      <p>Фильмы серии – действенный путь развития и пробуждения Души к вечным целям, ориентирам и ценностям. </p>
      <p>Это не просто фильмы, а духовный инструмент самопознания. Для многократного просмотра и постижения своего сердца и связи со всей Вселенной</p>
    
      <div className="container-inner">
        
        <ul>
          {audioFiles.map((song, index) => (
            <li key={song.title}>
              <button
                onClick={() => setCurrentSongIndex(index)}
                className={currentSongIndex === index ? 'button-active' : 'button-s'}
              >
                <span>
                  {index + 1 < 10 ? '0' + (index + 1) : index + 1}
                </span>
                <h2 class="h2-player">{song.title}</h2>
                <span>
                  {index === currentSongIndex ? (
                    <MdPause size={20} />
                  ) : (
                    <MdPlayArrow size={20} />
                  )}
                </span>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-auto">
        <AudioPlayer
          key={currentSongIndex}
          currentSong={currentSong}
          songCount={audioFiles.length}
          songIndex={currentSongIndex}
          onNext={() => setCurrentSongIndex((i) => (i < audioFiles.length - 1 ? i + 1 : i))}
          onPrev={() => setCurrentSongIndex((i) => (i > 0 ? i - 1 : i))}
        />
      </div>
    </div>
  );
}